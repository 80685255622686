import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`==Warning!== New theme uses fixed line heights:
`}<inlineCode parentName="p">{`[1, '16px', '20px', '24px', '28px', '32px', '40px', '48px', '56px']`}</inlineCode></p>
    <p>{`An information below is deprecated`}</p>
    <h2>{`Type scale`}</h2>
    <p>{`The typography scale is designed to work for Uchiru's product UI and marketing sites.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Scale`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1 line height (condensedUltra)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.25 line height (condensed)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.5 line height (default)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      